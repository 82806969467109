import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`És característic d’aquesta espècie l’olor forta a sabó que fa. Presenta un capell primer convex i després pla i ondulat, de 5 a 10 cm de diàmetre. En temps sec es pot clivellar o presentar esquames. És de color variable, des del gris verdós, verd oliva i blanquinós. El centre sol ser de color més fosc amb reflexos groguencs. El peu també és molt variable, de 3 a 5 o fins a 15 cm x 1 a 2 cm, generalment cilíndric, blanquinós al principi i després pren reflexos del mateix color que el capell. Les làmines estan espaiades, un poc sinuoses, blanquinoses amb tons verdosos, fràgils i sovint clivellades. Els exemplars vells poden presentar taques rogenques. Les espores són blanques en massa, el·lipsoides, de 5-6 x 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      